<template>
  <div
    class="relative p-8 bg-white rounded-lg w-86 h-96 dark:bg-gray-600 text-primary-900 dark:text-gray-300 review-shadow"
  >
    <template v-if="type === 1">
      <div class="flex items-center h-16 text-xs">
        <img
          class="relative object-cover w-16 h-16 rounded-full sm:absolute sm:left-0 sm:-ml-8"
          height="64"
          width="64"
          :src="image"
        />
        <div class="ml-4 sm:ml-6">
          <div class="font-bold">{{ name }}</div>
          <span>{{ company }}</span>
        </div>
      </div>
      <div class="mt-4">
        <h1 class="text-xl font-bold">"{{ title }}"</h1>
        <p class="mt-4 text-sm font-light">{{ review }}</p>
      </div>
    </template>
    <template v-else-if="type === 2">
      <div>
        <h1 class="text-xl font-bold">"{{ title }}"</h1>
        <p class="mt-4 text-sm font-light">{{ review }}</p>
      </div>
      <div class="flex items-center h-16 mt-8 text-xs">
        <img class="rounded-full" height="64" width="64" :src="image" />
        <div class="ml-2">
          <div class="font-bold">{{ name }}</div>
          <span>{{ company }}</span>
        </div>
      </div>
    </template>
    <template v-else-if="type === 3">
      <div class="flex items-center h-16 text-xs">
        <span class="w-32 break-words">{{ company }}</span>
        <img class="ml-16 rounded-full" height="64" width="64" :src="image" />
      </div>
      <div class="mt-4">
        <h1 class="text-xl font-bold">"{{ title }}"</h1>
        <p class="mt-4 text-sm font-light">{{ review }}</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CustomerReview',
  props: {
    company: { type: String, required: true },
    image: { type: String, required: true },
    name: { type: String, required: true },
    review: { type: String, required: true },
    title: { type: String, required: true },
    type: { type: Number, required: true }
  }
  /*computed: {
    image() {
      return require(`@/assets/images/customers/${this.image}.png`);
    }
  }*/
};
</script>

<style scoped>
.review-shadow {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
}
</style>
