/**
 * Module to work with data related to retrospectives.
 */
import { callApi } from '@/helpers/apiClient';
/**
 * Gets all the archived retros the user has been a part of.
 *
 * Archived: Pre-workspaces retro that does not belong to a team.
 */
export async function getArchivedRetros() {
    const response = await callApi({
        method: 'get',
        route: '/retros'
    });
    return response.retros;
}
/**
 * Returns the list of retro activities available.
 */
export async function getActivities() {
    const response = await callApi({
        method: 'get',
        route: '/activities'
    });
    return response.activities;
}
