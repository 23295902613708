import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { store } from '@/store';
import LandingPage from '@/views/landing/LandingPage.vue';
import RetroIndex from '@/views/retro/Index.vue';
export default defineComponent({
    components: {
        LandingPage,
        RetroIndex
    },
    beforeRouteEnter() {
        const loggedIn = store.getters.loggedIn;
        const workspaceId = store.getters.selectedWorkspaceId;
        if (loggedIn) {
            if (workspaceId) {
                return { name: 'showWorkspace', params: { workspaceId } };
            }
            return { name: 'indexWorkspace' };
        }
    },
    computed: {
        ...mapGetters(['loggedIn'])
    }
});
