<template>
  <div class="flex flex-col items-center p-12 -mx-5 bg-primary-100 dark:bg-gray-700">
    <p class="text-3xl font-bold text-primary-900 dark:text-gray-300">
      {{ $t('heading') }}
    </p>
    <p class="mt-4 text-xl font-light text-center text-gray-400">
      {{ $t('subheading') }}
    </p>
    <carousel class="mt-8" :items-to-show="1">
      <template #slides>
        <slide class="p-4 md:p-8">
          <customer-review
            class="mx-auto"
            :name="$t('customers.alain_chuard.name')"
            :title="$t('customers.alain_chuard.title')"
            :review="$t('customers.alain_chuard.review')"
            :company="$t('customers.alain_chuard.company')"
            :image="require('@/assets/images/customers/alain_chuard.png')"
            :type="1"
          />
        </slide>
        <slide class="p-4 md:p-8">
          <customer-review
            class="mx-auto"
            :name="$t('customers.felipe_cordoves.name')"
            :title="$t('customers.felipe_cordoves.title')"
            :review="$t('customers.felipe_cordoves.review')"
            :company="$t('customers.felipe_cordoves.company')"
            :image="require('@/assets/images/customers/felipe_cordoves.png')"
            :type="2"
          />
        </slide>
        <slide class="p-4 md:p-8">
          <customer-review
            class="mx-auto"
            :name="$t('customers.jordi_adame.name')"
            :title="$t('customers.jordi_adame.title')"
            :review="$t('customers.jordi_adame.review')"
            :company="$t('customers.jordi_adame.company')"
            :image="require('@/assets/images/customers/jordi_adame.png')"
            :type="3"
          />
        </slide>
      </template>
      <template #addons>
        <!-- <navigation /> -->
        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script>
/* eslint-disable */

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { mapGetters } from 'vuex';
import componentI18n from '@/i18n/componentI18n';
import CustomerReview from '@/components/landing/CustomerReview';
import constants from '@/constants';

export default {
  name: 'CustomerReviews',
  ...componentI18n('views.landing.customer_reviews'),
  components: {
    Carousel,
    CustomerReview,
    Navigation,
    Pagination,
    Slide
  },
  computed: {
    ...mapGetters(['isDarkMode'])
  },
  methods: {
    gray() {
      return constants.COLORS.gray['500'];
    },
    lightGray() {
      return constants.COLORS.gray['300'];
    },
    white() {
      return constants.COLORS.white;
    },
    black() {
      return constants.COLORS.wyeworks.black;
    }
  }
};
</script>
