import { defineStore } from 'pinia';
import { getActivities } from '@/data/retros';
export const useConstantStore = defineStore('constant', {
    state() {
        return { activities: [], activitiesMap: new Map() };
    },
    actions: {
        async loadActivities() {
            this.activities = await getActivities();
            this.activitiesMap = new Map(this.activities.map((a) => [a.id, a]));
        }
    }
});
